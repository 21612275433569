import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';

function Layout({ children }) {
  return (
    <>
      <Header />
      <div id="layoutSidenav" className="d-flex">
        <Sidebar />
        <div id="layoutSidenav_content" className="flex-grow-1">
          <main className="container-fluid py-4">
            {children}
          </main>
          <footer className="py-4 bg-light mt-auto">
            <div className="container-fluid px-4">
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-between small">
                <div className="text-muted mb-2 mb-md-0">
                  &copy; 2024 Sparkweb Technologies. All rights reserved.
                </div>
                <div>
                  <a href="#" className="text-decoration-none text-muted me-3">Privacy Policy</a>
                  <a href="#" className="text-decoration-none text-muted">Terms & Conditions</a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

export default Layout;
